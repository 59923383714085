<template>
  <div>
    <prism-editor
      v-model="codeText"
      language="json"
      style="border: 5px; oveflow: auto"
    ></prism-editor>
  </div>
</template>

<script>
import PrismEditor from "vue-prism-editor";
import "prismjs/components/prism-json";

// import theme style
import "@/assets/sprout-editor-dark.css";
import "@/assets/sprout-editor-light.css";

export default {
  components: {
    PrismEditor,
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    code: {
      type: String,
    },
  },

  mounted() {},

  data() {
    return {
      codeText: this.code,
    };
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
