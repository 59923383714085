<template>
  <v-container class="my-5">
    <!-- open issues -->
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-toolbar-title class="pr-8"
            >Customer mapping failures</v-toolbar-title
          >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary darken-1"
                text
                @click="getIssues()"
                icon
                v-on="on"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh data now</span>
          </v-tooltip>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-progress-linear
            :active="isLoading()"
            :indeterminate="true"
            absolute
            bottom
            color="blue-grey darken-3"
          ></v-progress-linear>
        </v-toolbar>

        <v-card>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="issues"
            :items-per-page="10"
            class="elevation-1"
            dense
            multi-sort
            single-select
            @click:row="rowClick"
          >
            <template v-slot:[`item.total_extended_price`]="{ item }">
              {{ formatMoney(item.total_extended_price) }}
            </template>

            <template v-slot:[`item.count`]="{ item }">
              {{ formatNumber(item.count) }}
            </template>

            <template v-slot:[`item.min_trans_date`]="{ item }">
              {{ formatDate(item.min_trans_date) }}
            </template>

            <template v-slot:[`item.max_trans_date`]="{ item }">
              {{ formatDate(item.max_trans_date) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- sales and geo data -->
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-toolbar dense color="primary">
            <v-toolbar-title>Sale's record data</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :disabled="!sale_data"
                  :href="getMapsURLfromSales()"
                  target="_blank"
                  :loading="isLoading()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-google-maps</v-icon>
                </v-btn>
              </template>
              <span>Search Google Maps</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :disabled="!sale_data"
                  :loading="isLoading()"
                  v-bind="attrs"
                  v-on="on"
                  @click="OnShowSalesRecord()"
                >
                  <v-icon>mdi-code-braces</v-icon>
                </v-btn>
              </template>
              <span>Show sales data</span>
            </v-tooltip>
          </v-toolbar>

          <div v-if="!!sale_data">
            <v-row class="ml-4">
              <v-col cols="2">
                <v-text-field
                  :value="sale_data.customer_id"
                  label="Customer ID"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>

              <v-col cols="8">
                <v-text-field
                  :value="sale_data.customer_name"
                  label="Name"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="ml-4">
              <v-col cols="2">
                <v-text-field
                  :value="sale_data.house_number"
                  label="House"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>

              <v-col cols="5">
                <v-text-field
                  :value="sale_data.address_1"
                  label="Address 1"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  :value="sale_data.address_2"
                  label="Address 2"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="ml-4">
              <v-col cols="6">
                <v-text-field
                  :value="sale_data.city"
                  label="City"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>

              <v-col cols="1">
                <v-text-field
                  :value="sale_data.state"
                  label="State"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :value="sale_data.zip"
                  label="Zip"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="ml-4">
              <v-col cols="8">
                <v-text-field
                  :value="sale_data.other_info"
                  label="Other info"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row class="mb-6" justify="center" no-gutters>
              <v-col md="auto">
                <p class="ma-6">No data found</p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-toolbar dense color="primary">
            <v-toolbar-title>Geocode data</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :disabled="!geocode_data"
                  :href="getMapsURLfromGeodata()"
                  target="_blank"
                  :loading="isLoading()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-google-maps</v-icon>
                </v-btn>
              </template>
              <span>Search Google Maps</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :disabled="!geocode_data"
                  :loading="isLoading()"
                  v-bind="attrs"
                  v-on="on"
                  @click="OnShowGeocodeRecord()"
                >
                  <v-icon>mdi-code-braces</v-icon>
                </v-btn>
              </template>
              <span>Show geocode data</span>
            </v-tooltip>
          </v-toolbar>

          <div v-if="isLoading()">
            <v-row class="mb-6" justify="center" no-gutters>
              <v-col md="auto">
                <p class="ma-6">Loading</p>
              </v-col>
            </v-row>
          </div>

          <div v-else-if="!geocode_data && !sale_data">
            <v-row class="mb-6" justify="center" no-gutters>
              <v-col md="auto">
                <p class="ma-6">No data</p>
              </v-col>
            </v-row>
          </div>

          <div
            v-else-if="!!geocode_data && geocode_data.status === 'ZERO_RESULTS'"
          >
            <v-row class="mb-6" justify="center" no-gutters>
              <v-col md="auto">
                <p class="ma-6">No results return from Google</p>
              </v-col>
            </v-row>
          </div>

          <div
            v-else-if="
              !!sale_data && !!geocode_data && geocode_data.status === 'OK'
            "
          >
            <v-row class="ml-4">
              <v-col cols="11">
                <v-text-field
                  :value="geocode_data.formatted_address"
                  label="Formatted address"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="ml-4">
              <v-col cols="2">
                <v-text-field
                  :value="geocode_data.house_number"
                  label="House"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>

              <v-col cols="5">
                <v-text-field
                  :value="geocode_data.street"
                  label="Address 1"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  :value="geocode_data.street_2"
                  label="Address 2"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="ml-4">
              <v-col cols="6">
                <v-text-field
                  :value="geocode_data.city"
                  label="City"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>

              <v-col cols="1">
                <v-text-field
                  :value="geocode_data.state"
                  label="State"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :value="geocode_data.zip"
                  label="Zip"
                  readonly
                  :loading="isLoading()"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row class="mb-6" justify="center" no-gutters>
              <v-col md="auto">
                <p class="ma-6">
                  No data found (may not have been mapped, yet)
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- best matches -->
    <v-row>
      <v-col cols="12">
        <v-toolbar elevation="1">
          <v-toolbar-title class="pr-8">Best matches</v-toolbar-title>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="warehouseLoading"
                :disabled="warehouseLoading"
                color="primary darken-1"
                text
                @click="showColumnsDialog()"
                icon
                v-on="on"
              >
                <v-icon>mdi-view-column-outline</v-icon>
              </v-btn>
            </template>
            <span>Select columns</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :disabled="!sale_data"
                :loading="warehouseLoading"
                color="primary darken-1"
                v-bind="attrs"
                v-on="on"
                @click="showWarehouseDialog(sale_data)"
              >
                <v-icon>mdi-home-plus</v-icon>
              </v-btn>
            </template>
            <span>Create new warehouse</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :disabled="!sale_data"
                :loading="warehouseLoading"
                color="primary darken-1"
                v-bind="attrs"
                v-on="on"
                @click="manualMappingDialog = true"
              >
                <v-icon>mdi-link-variant-plus</v-icon>
              </v-btn>
            </template>
            <span>Create manual mapping</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :disabled="!sale_data"
                :loading="!!warehouseLoading"
                color="primary darken-1"
                v-bind="attrs"
                v-on="on"
                @click="cloningDialog = true"
              >
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>Create manual clone</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :disabled="!sale_data"
                :loading="!!warehouseLoading"
                color="primary darken-1"
                v-bind="attrs"
                v-on="on"
                @click="queryDialog = true"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search warehouses by name</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="warehouseSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-progress-linear
            :active="isWarehouseLoading()"
            :indeterminate="true"
            absolute
            bottom
            color="blue-grey darken-3"
          ></v-progress-linear>
        </v-toolbar>

        <v-data-table
          :headers="visibleColumns"
          :search="warehouseSearch"
          :items="suggestions"
          :items-per-page="50"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  icon
                  color="primary darken-1"
                  class="d-inline"
                  v-on="on"
                  @click="showWarehouseMapping(item)"
                >
                  <v-icon small>mdi-link-variant-plus</v-icon>
                </v-btn>
              </template>
              <span>Map to warehouse</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  icon
                  color="primary darken-1"
                  class="d-inline"
                  v-on="on"
                  @click="cloneWarehouse(item)"
                >
                  <v-icon small color="primary">mdi-content-duplicate</v-icon>
                </v-btn>
              </template>
              <span>Clone warehouse</span>
            </v-tooltip>
          </template>

          <!--  -->

          <template v-slot:[`item.match`]="{ item }">
            <v-chip :color="getMatchColor(item.total_score)" dark>
              {{ getMatchText(item.total_score) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <confirm ref="confirm"></confirm>
    <!-- warehouse dialog -->
    <v-dialog v-model="warehouseDialog" width="66%">
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>New Warehouse</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="warehouseForm" v-model="warehouseIsValid">
            <v-row class="ml-4 mr-4 mt-6">
              <v-col cols="2">
                <v-select
                  v-model="addressSelection"
                  :items="addressSources"
                  label="Address source"
                ></v-select>
              </v-col>
              <v-col cols="9">
                <div class="mt-5 ml-8 text-left" v-if="!!template">
                  <p>
                    Name matched pattern for template:
                    <span class="green--text text--darken-1 font-weight-bold">
                      {{ template.name }}
                    </span>
                  </p>
                </div>
                <div class="mt-5 ml-8 text-left font-weight-bold" v-else>
                  <p>{{ templateMessage }}</p>
                </div>
              </v-col>
            </v-row>

            <v-row class="ml-4 mr-4">
              <v-col cols="2">
                <v-text-field
                  :value="getCustomerID()"
                  label="Customer ID"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="8">
                <v-text-field
                  v-model="whName"
                  label="Name"
                  :rules="warehouseNameRules"
                ></v-text-field>
              </v-col>

              <v-col cols="2">
                <v-text-field
                  v-model="whStoreNumber"
                  label="Store"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="ml-4 mr-4">
              <v-col cols="2">
                <v-text-field
                  v-model="whHouseNumber"
                  label="House"
                ></v-text-field>
              </v-col>

              <v-col cols="7">
                <v-text-field
                  v-model="whStreet"
                  label="Address 1"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  v-model="whStreet_2"
                  label="Address 2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="ml-4 mr-4">
              <v-col cols="5">
                <v-text-field v-model="whCity" label="City"></v-text-field>
              </v-col>

              <v-col cols="1">
                <v-combobox
                  v-model="whState"
                  :items="stateAbbr"
                  label="State"
                  :rules="stateRules"
                  v-on:change="changeState"
                ></v-combobox>
              </v-col>

              <v-col cols="2">
                <v-text-field v-model="whZip" label="Zip"></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  :value="getGeoCode()"
                  label="Geocode"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="ml-4 mr-4">
              <v-col cols="5">
                <v-autocomplete
                  v-model="whBuyingSubgroup"
                  :items="subgroups"
                  label="Subgroup"
                  :item-text="getSubgroupDisplayText"
                  item-value="key"
                  :return-object="true"
                  clearable
                  :rules="buyingSubgroupRules"
                ></v-autocomplete>
              </v-col>

              <v-col cols="3">
                <v-select
                  v-model="whMarketSegment"
                  :items="marketSegments"
                  label="Market segment"
                  :item-text="getDisplayText"
                  item-value="key"
                  :return-object="true"
                  clearable
                  :rules="marketSegmentRules"
                  v-on:change="changeMarketSegment"
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="whMarsDistributor"
                  :items="distributors"
                  label="Distributor"
                  :item-text="getDisplayText"
                  item-value="key"
                  :return-object="true"
                  clearable
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="ml-4 mr-4">
              <v-col cols="4">
                <v-select
                  v-model="whMarsDirectSalesTerritory"
                  :items="validDirectSalesTerritories"
                  label="Mars direct sales territory"
                  :item-text="getDisplayText"
                  item-value="key"
                  :return-object="true"
                  clearable
                  :rules="marsDirectSalesTerritoryRules"
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-select
                  v-model="whMWCBrokerSalesTerritory"
                  :items="salesTerritories"
                  label="MWC broker sales territory"
                  :item-text="getDisplayText"
                  item-value="key"
                  :return-object="true"
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="whSubchannel"
                  :items="subchannels"
                  label="Subchannel"
                  :item-text="getSubchannelDisplayText"
                  item-value="key"
                  :return-object="true"
                  :rules="subchannelRules"
                  clearable
                ></v-select>
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="validateWarehouse"
            >Validate warehouse</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeWarehouseDialog"
            >Close</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="createNewWarehouse"
            type="submit"
            :disabled="!warehouseIsValid"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- columns dialog -->
    <v-dialog v-model="columnsDialog" width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Columns to display</span>
        </v-card-title>
        <v-card-text>
          <div
            v-for="(obj, index) in suggestionHeaders"
            :key="suggestionHeaders[index].text"
          >
            <v-checkbox dense :label="obj.text" v-model="obj.visible">
            </v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="columnsDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- manual mapping dialog -->
    <v-dialog v-model="manualMappingDialog" width="500px">
      <v-card>
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title class="white--text"
            >Manual warehouse mapping</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text>
          <div v-if="!!sale_data" class="mt-8">
            <p class="subtitle-1">Pipeline: {{ sale_data.pipeline_id }}</p>
            <p class="subtitle-1">Customer ID: {{ sale_data.customer_id }}</p>
            <v-text-field
              label="Warehouse ID"
              single-line
              v-model="manualMappingWarehouseID"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="manualMappingDialog = false">Close</v-btn>
          <v-btn color="green darken-1" text @click="createManualMapping()"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- mapping dialog -->
    <v-dialog v-model="mappingDialog" width="900px">
      <v-card>
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title class="white--text"
            >Warehouse mapping</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text>
          <div v-if="!!sale_data" class="mt-8">
            <v-row>
              <v-col>
                <p>Are you sure you want to create the following mapping?</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="5">
                <v-card>
                  <v-card-text>
                    <v-text-field
                      dense
                      readonly
                      label="Pipeline ID"
                      :value="sale_data.pipeline_id"
                    ></v-text-field>
                    <v-text-field
                      dense
                      readonly
                      label="Customer ID"
                      :value="sale_data.customer_id"
                    ></v-text-field>
                    <v-text-field
                      dense
                      readonly
                      label="Name"
                      :value="sale_data.customer_name"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="2">
                <h3 class="text-center">to</h3>
              </v-col>

              <v-col cols="5">
                <v-card v-if="!!currentWarehouse">
                  <v-card-text>
                    <v-text-field
                      dense
                      readonly
                      label="Warehouse ID"
                      :value="currentWarehouse.id"
                    ></v-text-field>
                    <v-text-field
                      dense
                      readonly
                      label="Name"
                      :value="currentWarehouse.name"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-select
              :items="addressOptions"
              label="Address options"
              v-model="addressOption"
              item-text="value"
              item-value="key"
              return-object
            >
            </v-select>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="mappingDialog = false">Close</v-btn>
          <v-btn color="green darken-1" text @click="createWarehouseMapping()"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- manual cloning dialog -->
    <v-dialog v-model="cloningDialog" width="500px">
      <v-card>
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title class="white--text"
            >Manual warehouse cloning</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text>
          <div v-if="!!sale_data" class="mt-8">
            <v-text-field
              label="Warehouse ID"
              single-line
              v-model="manualCloningWarehouseID"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cloningDialog = false">Close</v-btn>
          <v-btn color="green darken-1" text @click="createManualCloning()"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- query dialog -->
    <v-dialog v-model="queryDialog" width="500px">
      <v-card>
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title class="white--text"
            >Manual warehouse search</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text>
          <div v-if="!!sale_data" class="mt-8">
            <v-text-field
              label="Warehouse name"
              single-line
              v-model="manualWarehouseName"
            ></v-text-field>
          </div>
          <div>
            <v-combobox
              v-model="selectedStates"
              label="States"
              multiple
              chips
            ></v-combobox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="queryDialog = false">Close</v-btn>
          <v-btn color="green darken-1" text @click="searchByName()"
            >Search</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/services/api";
import { formatMoney, formatDate, formatNumber, stateAbbr } from "@/lib/utils";
import RawJson from "@/components/utils/RawJson";
import confirm from "@/components/utils/Confirm";
import { startOfToday, format } from "date-fns";

export default {
  components: {
    confirm,
  },

  data() {
    return {
      user: null,
      search: "",
      warehouseSearch: "",
      selectedIssue: null,

      headers: [
        { text: "Issue ID", value: "id" },
        { text: "Pipeline", value: "pipeline_id" },
        { text: "Name", value: "pipeline_name" },
        { text: "Details", value: "details" },
        { text: "Min trans date", value: "min_trans_date" },
        { text: "Max trans date", value: "max_trans_date" },
        { text: "Total extended", value: "total_extended_price" },
        { text: "Count", value: "count" },
      ],

      suggestionHeaders: [
        { text: "ID", value: "id", visible: true, filterable: false },
        { text: "Name", value: "name", visible: true },
        { text: "Segment", value: "market_segment_name", visible: true },
        { text: "Subgroup", value: "buying_subgroup_name", visible: true },
        {
          text: "Sales territory",
          value: "mars_direct_sales_territory_id",
          visible: false,
        },
        { text: "House", value: "house_number", visible: true },
        { text: "Street", value: "street", visible: true },
        { text: "Street 2", value: "street_2", visible: false },
        { text: "City", value: "city", visible: true },
        { text: "State", value: "state", visible: false },
        { text: "Zip", value: "zip", visible: true },
        {
          text: "Distance",
          value: "distance",
          visible: true,
          filterable: false,
        },
        {
          text: "Distance score",
          value: "distance_score",
          visible: false,
          filterable: false,
        },
        {
          text: "City score",
          value: "city_similarity",
          visible: false,
          filterable: false,
        },
        {
          text: "Zip score",
          value: "zip_score",
          visible: false,
          filterable: false,
        },
        {
          text: "Name score",
          value: "name_similarity",
          visible: false,
          filterable: false,
        },
        {
          text: "Street score",
          value: "street_similarity",
          visible: false,
          filterable: false,
        },
        {
          text: "Template score",
          value: "template_score",
          visible: false,
          filterable: false,
        },
        { text: "Total score", value: "total_score", visible: true },
        { text: "Match", sortable: false, value: "match", visible: true },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
          visible: true,
          filterable: false,
        },
      ],

      issues: [],
      suggestions: [],
      sale_data: null,
      geocode_data: null,
      loading: false,
      warehouseLoading: false,

      // dialog toggles
      columnsDialog: false,
      warehouseDialog: false,
      manualMappingDialog: false,
      mappingDialog: false,
      cloningDialog: false,
      queryDialog: false,

      subgroups: [],
      marketSegments: [],
      salesTerritories: [],
      distributors: [],
      vendorLocations: [],
      templates: [],
      subchannels: [],

      validDirectSalesTerritories: [],

      addressSources: ["Sales record", "Google"],
      addressSelection: "Sales record",

      whName: "",
      whVendorLocation: null,
      whMarketSegment: null,
      whMarsDirectSalesTerritory: null,
      whHouseNumber: "",
      whStreet: "",
      whStreet_2: "",
      whCity: "",
      whState: "",
      whZip: "",
      whPhone: "",
      whBuyingSubgroup: null,
      whStoreNumber: "",
      whGeocode: "",
      whMarsDistributor: null,
      whMWCBrokerSalesTerritory: null,
      whSubchannel: null,

      manualMappingWarehouseID: null,
      manualCloningWarehouseID: null,
      manualWarehouseName: "",
      selectedStates: [],

      warehouseIsValid: false,

      stateAbbr: stateAbbr,

      template: null,
      templateMessage: "",

      // rules
      marketSegmentRules: [(v) => !!v || "Market segment is required"],
      warehouseNameRules: [(v) => !!v || "Name is required"],
      subchannelRules: [(v) => !!v || "Subchannel is required"],

      marsDirectSalesTerritoryRules: [
        (v) => !!v || "Sales territory is required",
        (v) => this.validateDirectSalesTerritory(v),
      ],

      stateRules: [(v) => this.validateState(v)],
      buyingSubgroupRules: [(v) => this.validateSubgroup(v)],

      addressOptions: [
        { key: 0, value: "Do not update" },
        { key: 1, value: "Update from sales record" },
        { key: 2, value: "Update from geo mapping" },
      ],
      addressOption: null,

      currentWarehouse: null,
    };
  },

  methods: {
    isLoading() {
      return this.loading;
    },

    isWarehouseLoading() {
      return this.warehouseLoading;
    },

    formatMoney(value) {
      // stupid, but here so the linter doesn't complain
      return formatMoney(value);
    },

    formatNumber(value) {
      // stupid, but here so the linter doesn't complain
      return formatNumber(value);
    },

    formatDate(value) {
      // stupid, but here so the linter doesn't complain
      return formatDate(value);
    },

    getPipelineInfo(item) {
      return item.pipeline_name;
    },

    getIssues() {
      this.loading = true;
      this.issues = [];
      this.sale_data = null;
      this.geocode_data = null;
      this.suggestions = [];
      this.selectedIssue = null;
      this.search = "";
      this.warehouseSearch = "";

      let startOfDay = format(startOfToday(), "yyyy-MM-dd");

      let params = {
        filters: [
          {
            column_name: "failure_code_description",
            value: "Customer mapping",
          },
          { column_name: "created", operator: "<", value: startOfDay },
        ],
      };

      api
        .getOpenIssueSummary(params)
        .then((response) => {
          this.loading = false;
          this.issues = response.data.rows || [];
        })
        .catch(() => {
          this.loading = false;
          this.issues = [];
        });
    },

    getWarehouseFkData() {
      if (this.subgroups.length === 0) {
        let params = [
          { table_name: "buying_subgroup" },
          { table_name: "market_segment" },
          { table_name: "sales_territory" },
          { table_name: "distributor" },
          { table_name: "subchannel", value_column_name: "display_name" },
          {
            table_name: "vendor_location",
            filters: [
              { column_name: "id", operator: "IN", value: "(0, 1, 2, 3, 9)" },
            ],
          },
        ];

        api
          .callDBFunc("db-1", "get_fk_data", params, "sprout")
          .then((response) => {
            let r;
            for (r of response.data.rows) {
              switch (r.table_name) {
                case "buying_subgroup":
                  this.subgroups = r.data
                    ? r.data.sort((a, b) => a.key - b.key)
                    : [];
                  break;
                case "market_segment":
                  this.marketSegments = r.data
                    ? r.data.sort((a, b) => a.key - b.key)
                    : [];
                  break;
                case "sales_territory":
                  this.salesTerritories = r.data
                    ? r.data.sort((a, b) => a.key - b.key)
                    : [];
                  break;
                case "distributor":
                  this.distributors = r.data
                    ? r.data.sort((a, b) => a.key - b.key)
                    : [];
                  break;
                case "subchannel":
                  this.subchannels = r.data
                    ? r.data.sort((a, b) => a.key - b.key)
                    : [];
                  break;
                case "vendor_location":
                  this.vendorLocations = r.data
                    ? r.data.sort((a, b) => a.key - b.key)
                    : [];
                  break;
              }
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    searchByName() {
      this.queryDialog = false;
      this.warehouseLoading = true;
      this.warehouseSearch = "";
      this.suggestions = [];

      let params = { customer_name: this.manualWarehouseName, limit: 10000 };

      if (this.selectedStates && this.selectedStates.length > 0) {
        params["state"] = this.selectedStates;
      }

      api
        .callDBFunc("etl-2", "get_best_match_warehouses", params)
        .then((response) => {
          this.warehouseLoading = false;
          this.suggestions = response.data.rows ? response.data.rows : [];
        });
    },

    matchesPattern(customerName, pattern) {
      if (pattern.match_type === "regexp") {
        const re = pattern.case_sensitive
          ? RegExp(pattern.value)
          : RegExp(pattern.value, "i");
        return re.exec(customerName) !== null;
      } else {
        const s = pattern.case_sensitive
          ? customerName
          : customerName?.toUpperCase();
        const p = pattern.case_sensitive
          ? pattern.value
          : pattern.value.toUpperCase();
        return s?.includes(p) || false;
      }
    },

    findTemplate(customerName) {
      let t;
      let p;
      this.template = null;
      this.templateMessage = "";

      for (t of this.templates) {
        if (this.template) {
          break;
        }

        for (p of t.options.patterns) {
          if (this.matchesPattern(customerName, p)) {
            this.template = t;
            break;
          }
        }
      }

      if (this.template) {
        if (
          this.template.options.market_segment &&
          this.template.options.market_segment.key
        ) {
          this.whMarketSegment = this.template.options.market_segment;
        }

        if (
          this.template.options.buying_subgroup &&
          this.template.options.buying_subgroup.key
        ) {
          this.whBuyingSubgroup = this.template.options.buying_subgroup;
        }

        if (
          this.template.options.mars_direct_sales_territory &&
          this.template.options.mars_direct_sales_territory.key
        ) {
          this.whMarsDirectSalesTerritory =
            this.template.options.mars_direct_sales_territory;
        }

        if (
          this.template.options.subchannel &&
          this.template.options.subchannel.key
        ) {
          this.whSubchannel = this.template.options.subchannel;
        }

        this.whVendorLocation = null;
        this.whGeocode = null;
        this.whMarsDistributor = null;
        this.whMWCBrokerSalesTerritory = null;
      } else {
        this.templateMessage = "No template match found";
      }
    },

    getWarehouseTemplates() {
      const self = this;

      this.templates = [];

      api
        .callDBFunc("db-1", "get_warehouse_templates", {}, "sprout")
        .then((response) => {
          self.templates = response.data.rows ? response.data.rows : [];
          self.templates = self.templates.sort((a, b) => {
            return a.priority - b.priority;
          });
        });
    },

    rowClick(item, row) {
      row.select(true);
      this.loading = true;
      this.warehouseLoading = true;
      this.selectedIssue = item;
      this.warehouseSearch = "";

      // this.sale_data = null;
      // this.geocode_data = null;

      this.getWarehouseFkData();

      let params = {
        sale_processing_issue_id: item.id,
      };

      api.getCustomerMappingSuggestions(params).then((response) => {
        this.loading = false;
        this.warehouseLoading = false;

        this.suggestions = response.data.rows[0].suggestions
          ? response.data.rows[0].suggestions.sort(
              (a, b) => b.total_score - a.total_score
            )
          : [];
        this.sale_data = response.data.rows[0].sale_data;
        this.manualWarehouseName = this.sale_data.customer_name;
        this.geocode_data = response.data.rows[0].geocode_data;
      });
    },

    getMapsURLfromGeodata() {
      if (this.geocode_data) {
        return (
          "https://www.google.com/maps/search/?api=1&query=" +
          encodeURIComponent(this.geocode_data.formatted_address)
        );
      } else {
        return "https://www.google.com/maps";
      }
    },

    getMapsURLfromSales() {
      if (this.sale_data) {
        let q = "";

        if (this.sale_data.customer_name) {
          q = this.sale_data.customer_name + " ";
        }

        if (this.sale_data.house_number) {
          q = q + this.sale_data.house_number + " ";
        }

        if (this.sale_data.address_1) {
          q = q + this.sale_data.address_1 + " ";
        }

        if (this.sale_data.address_2) {
          q = q + this.sale_data.address_2 + " ";
        }

        if (this.sale_data.city) {
          q = q + this.sale_data.city + " ";
        }

        if (this.sale_data.state) {
          q = q + this.sale_data.state + " ";
        }

        if (this.sale_data.zip) {
          q = q + this.sale_data.zip + " ";
        }

        return (
          "https://www.google.com/maps/search/?api=1&query=" +
          encodeURIComponent(q)
        );
      } else {
        return "https://www.google.com/maps";
      }
    },

    addResolution(issueID, params) {
      const self = this;
      params.email = this.user.email;
      params.sale_data = this.sale_data;

      let obj = {
        sale_processing_issue_id: issueID,
        data: params,
      };

      api.createSaleProcessingIssueResolution(obj).then((response) => {
        const d = response.data.rows[0];
        if (d.status === "OK") {
          self.issues = self.issues.filter(
            (b) => b.id !== self.selectedIssue.id
          );
          self.selectedIssue = null;
          self.sale_data = null;
          self.geocode_data = null;
          self.suggestions = [];
          self.clearWarehouseInfo();
          self.currentWarehouse = null;
        }
      });
    },

    showWarehouseMapping(item) {
      this.currentWarehouse = item;
      this.addressOption = this.addressOptions[0];
      this.mappingDialog = true;
    },

    createWarehouseMapping() {
      this.mappingDialog = false;

      const params = {
        type: "add_customer_mapping",
        pipeline_id: this.sale_data.pipeline_id,
        customer_id: this.sale_data.customer_id,
        mars_vendor_warehouse_id: this.currentWarehouse.id,
        total_score: this.currentWarehouse?.total_score,
      };

      params.update_address = !(this.addressOption.key === 0);

      let address = null;

      if (this.addressOption.key < 2) {
        address = {
          house_number: this.sale_data.house_number,
          street: this.sale_data.address_1,
          street_2: this.sale_data.address_2,
          city: this.sale_data.city,
          state: this.sale_data.state,
          zip: this.sale_data.zip,
        };
      } else {
        address = {
          house_number: this.geocode_data.house_number,
          street: this.geocode_data.street,
          street_2: this.geocode_data.street_2,
          city: this.geocode_data.city,
          state: this.geocode_data.state,
          zip: this.geocode_data.zip,
          lat: this.geocode_data.lat,
          lng: this.geocode_data.lng,
        };
      }

      params.address = address;

      this.addResolution(this.selectedIssue.id, params);
    },

    showColumnsDialog() {
      this.columnsDialog = true;
    },

    showQueryDialog() {
      this.queryDialog = true;
    },

    setNewWarehouseDefaults() {
      this.whName = this.sale_data.customer_name;

      if (this.addressSelection === this.addressSources[0]) {
        this.whHouseNumber = this.sale_data.house_number
          ? this.sale_data.house_number
          : "";
        this.whStreet = this.sale_data.address_1
          ? this.sale_data.address_1
          : "";
        this.whStreet_2 = this.sale_data.address_2
          ? this.sale_data.address_2
          : "";
        this.whCity = this.sale_data.city ? this.sale_data.city : "";
        this.whState = this.sale_data.state ? this.sale_data.state : "";
        this.whZip = this.sale_data.zip ? this.sale_data.zip : "";
      } else {
        this.whHouseNumber = this.geocode_data
          ? this.geocode_data.house_number
          : "";
        this.whStreet = this.geocode_data ? this.geocode_data.street : "";
        this.whStreet_2 = this.geocode_data ? this.geocode_data.street_2 : "";
        this.whCity = this.geocode_data ? this.geocode_data.city : "";
        this.whState = this.geocode_data ? this.geocode_data.state : "";
        this.whZip = this.geocode_data ? this.geocode_data.zip : "";
      }

      this.changeState(this.whState);
    },

    showWarehouseDialog() {
      this.addressSelection = this.addressSources[0];
      this.setNewWarehouseDefaults();
      this.findTemplate(this.sale_data.customer_name);
      this.validDirectSalesTerritories = this.salesTerritories;
      this.warehouseDialog = true;
    },

    getCustomerID() {
      return this.sale_data ? this.sale_data.customer_id : "";
    },

    getGeoCode() {
      if (this.geocode_data) {
        return `${this.geocode_data.lng}, ${this.geocode_data.lat}`;
      }

      return "";
    },

    cloneWarehouse(warehouse) {
      this.getWarehouseInfo(warehouse.id, true);
    },

    createNewWarehouse() {
      const tid = this.template ? this.template.id : null;

      const params = {
        type: "create_warehouse",
        pipeline_id: this.sale_data.pipeline_id,
        customer_id: this.sale_data.customer_id,
        warehouse_template_id: tid,

        name: this.whName,
        vendor_location: this.whVendorLocation,
        market_segment: this.whMarketSegment,
        mars_direct_sales_territory: this.whMarsDirectSalesTerritory,
        house_number: this.whHouseNumber,
        street: this.whStreet,
        street_2: this.whStreet_2,
        city: this.whCity,
        state: this.whState,
        zip: this.whZip,
        phone: this.whPhone,
        buying_subgroup: this.whBuyingSubgroup,
        store_number: this.whStoreNumber,
        geocode: this.whGeocode,
        mars_distributor: this.whMarsDistributor,
        mwc_broker_sales_territory: this.whMWCBrokerSalesTerritory,
        subchannel: this.whSubchannel,
      };

      this.addResolution(this.selectedIssue.id, params);
      this.warehouseDialog = false;
    },

    OnShowSalesRecord() {
      this.$modal.show(
        RawJson,
        {
          title: `Pending sale ID: ${this.sale_data.id}`,
          code: JSON.stringify(this.sale_data, null, 2),
        },
        {
          width: "60%",
          height: "80%",
        }
      );
    },

    OnShowGeocodeRecord() {
      this.$modal.show(
        RawJson,
        {
          code: JSON.stringify(this.geocode_data, null, 2),
        },
        {
          width: "60%",
          height: "80%",
        }
      );
    },

    getMatchText(score) {
      if (!score && score !== 0.0) {
        return "Unknown";
      }

      let s = parseFloat(score);

      if (s >= 5.0) {
        return "High";
      } else if (s >= 4.0) {
        return "Medium";
      } else if (s > 3.0) {
        return "Low";
      } else {
        return "Very low";
      }
    },

    getMatchColor(score) {
      let matchText = this.getMatchText(score);

      switch (matchText) {
        case "High":
          return "green darken-3";
        case "Medium": // foo is 0 so criteria met here so this block will run
          return "yellow darken-3";
        case "Low":
          return "orange darken-4";
        case "Very low":
          return "red darken-3";
        default:
          return "black";
      }
    },

    getWarehouseInfo(warehouseID, showDialog) {
      const self = this;
      this.template = null;

      api.getWarehouse({ id: warehouseID }).then((response) => {
        const w = response.data.rows[0];

        this.addressSelection = this.addressSources[0];
        this.setNewWarehouseDefaults();

        self.whVendorLocation = w.vendor_location_id;
        self.whMarketSegment = w.market_segment_id;
        this.changeMarketSegment(self.whMarketSegment);
        self.whMarsDirectSalesTerritory = w.mars_direct_sales_territory_id;
        self.whBuyingSubgroup = w.buying_subgroup_id;
        self.whGeocode = w.geocode;
        self.whMarsDistributor = w.mars_distributor_id;
        self.whMWCBrokerSalesTerritory = w.mwc_broker_sales_territory_id;
        self.whSubchannel = w.subchannel_id;

        self.templateMessage = `Values used from warehouse: ${warehouseID}`;

        if (showDialog) {
          this.warehouseDialog = true;
        }
      });
    },

    createManualMapping() {
      const params = {
        type: "add_customer_mapping",
        pipeline_id: this.sale_data.pipeline_id,
        customer_id: this.sale_data.customer_id,
        mars_vendor_warehouse_id: this.manualMappingWarehouseID,
      };

      this.addResolution(this.selectedIssue.id, params);
      this.manualMappingDialog = false;
    },

    createManualCloning() {
      this.getWarehouseInfo(this.manualCloningWarehouseID, true);
      this.cloningDialog = false;
    },

    getDisplayText(item) {
      if (item && item.key) {
        return `${item.key} - ${item.value}`;
      } else {
        return "";
      }
    },

    getSubgroupDisplayText(item) {
      if (item && item.key) {
        return `${item.value} (${item.key})`;
      } else {
        return "";
      }
    },

    getSubchannelDisplayText(item) {
      if (item && item.key) {
        return `${item.value} (${item.key})`;
      } else {
        return "";
      }
    },

    clearWarehouseInfo() {
      this.whName = "";
      this.whVendorLocation = null;
      this.whMarketSegment = null;
      this.whMarsDirectSalesTerritory = null;
      this.whHouseNumber = "";
      this.whStreet = "";
      this.whStreet_2 = "";
      this.whCity = "";
      this.whState = "";
      this.whZip = "";
      this.whPhone = "";
      this.whBuyingSubgroup = null;
      this.whStoreNumber = "";
      this.whGeocode = "";
      this.whMarsDistributor = null;
      this.whMWCBrokerSalesTerritory = null;
      this.whSubchannel = null;
    },

    validateWarehouse() {
      this.$refs.warehouseForm.validate();
    },

    closeWarehouseDialog() {
      this.warehouseDialog = false;
      this.$refs.warehouseForm.resetValidation();
      this.clearWarehouseInfo();
    },

    validateState(value) {
      if (this.whCity && this.whCity !== "" && !value) {
        return "State is required when city is supplied";
      }

      return true;
    },

    validateSubgroup(value) {
      if (value && value.key) {
        return true;
      }

      const t = this.template?.options?.buying_subgroup?.allow_null;

      if (typeof t === "undefined") {
        return true;
      }

      if (!t) {
        return "Subgroup is required by template";
      }

      return true;
    },

    validateDirectSalesTerritory() {
      if (this.whMarsDirectSalesTerritory?.key) {
        for (let o of this.validDirectSalesTerritories) {
          if (o.key === this.whMarsDirectSalesTerritory.key) {
            return true;
          }
        }

        return `Territory ${this.whMarsDirectSalesTerritory.key} is not in eligible list.`;
      } else {
        return true;
      }
    },

    changeMarketSegment(value) {
      if (value && value.key) {
        let x;
        let spec = [1, 11, 12, 24, 28, 29, 31, 32, 33, 36, 37];
        let foodservice = [80, 85, 86, 88, 89];
        let defaultValue;

        const territory50 = [
          "AK",
          "AL",
          "AR",
          "AZ",
          "CA",
          "CO",
          "FL",
          "GA",
          "HI",
          "ID",
          "LA",
          "MS",
          "MT",
          "NC",
          "NM",
          "NV",
          "OK",
          "OR",
          "SC",
          "TN",
          "TX",
          "UT",
          "WA",
          "WY",
        ];

        switch (value.key) {
          case 1:
            defaultValue = 1101;
            x = [defaultValue];
            break;
          case 2:
            defaultValue = 33;
            x = foodservice.concat([defaultValue]);
            break;
          case 3:
            defaultValue = 1103;
            x = [defaultValue];
            break;
          case 4:
            defaultValue = 1104;
            x = [defaultValue];
            break;
          case 5:
            x = foodservice;
            break;

          case 6:
            defaultValue = 33;
            x = foodservice.concat([defaultValue]);
            break;

          case 7:
            defaultValue = 50;
            x = [defaultValue];
            break;

          case 8:
            defaultValue = 23;
            x = spec.concat([defaultValue]);
            break;

          case 9:
            defaultValue = 1109;
            x = spec.concat([defaultValue]);
            break;

          case 10:
            x = [27, 50];

            if (this.whState && territory50.includes(this.whState)) {
              defaultValue = 50;
            } else {
              defaultValue = 27;
            }
            break;

          case 11:
            defaultValue = 1111;
            x = [defaultValue];
            break;

          case 12:
            defaultValue = 1112;
            x = [defaultValue];
            break;

          case 13:
            defaultValue = 1113;
            x = [defaultValue];
            break;

          case 14:
            defaultValue = 1114;
            x = [defaultValue];
            break;

          case 15:
            defaultValue = 1115;
            x = spec.concat([defaultValue]);
            break;

          case 16:
            defaultValue = 50;
            x = [defaultValue];
            break;

          case 17:
            defaultValue = 1117;
            x = [defaultValue];
            break;

          case 18:
            defaultValue = 1118;
            x = [defaultValue];
            break;

          case 19:
            defaultValue = 1119;
            x = [defaultValue];
            break;

          case 21:
            defaultValue = 1121;
            x = [defaultValue];
            break;

          case 22:
            x = spec;
            break;

          case 23:
            defaultValue = 1123;
            x = [defaultValue];
            break;

          case 26:
            x = spec;
            break;

          case 29:
            defaultValue = 1129;
            x = [defaultValue];
            break;
        }

        let template_territory_id = (
          ((this.template || {}).options || {}).mars_direct_sales_territory ||
          {}
        ).key;

        if (
          this.template &&
          this.template.options &&
          this.template.options.mars_direct_sales_territory &&
          this.template.options.mars_direct_sales_territory.key
        ) {
          template_territory_id = this.template.mars_direct_sales_territory.key;
        }

        x.push(template_territory_id);

        if (x && x.length > 0) {
          this.validDirectSalesTerritories = this.salesTerritories.filter(
            (item) => x.includes(item.key)
          );
        } else {
          this.validDirectSalesTerritories = this.salesTerritories.filter(
            (item) => item.key > 0
          );
        }

        if (template_territory_id) {
          this.whMarsDirectSalesTerritory =
            this.template.options.mars_direct_sales_territory;
        } else {
          if (this.validDirectSalesTerritories.length === 1) {
            this.whMarsDirectSalesTerritory =
              this.validDirectSalesTerritories[0];
          }
        }

        if (defaultValue) {
          this.whMarsDirectSalesTerritory = this.findTerritory(defaultValue);
        }
      }
    },

    findTerritory(key) {
      if (!key) {
        return;
      }

      return this.salesTerritories.find((territory) => territory.key == key);
    },

    changeState(value) {
      if (this.sale_data && this.sale_data.data_type === "FS") {
        const territory86 = [
          "CT",
          "DE",
          "IN",
          "KY",
          "MA",
          "MD",
          "ME",
          "MI",
          "NH",
          "NJ",
          "NY",
          "OH",
          "PA",
          "RI",
          "VA",
          "VT",
          "WV",
          "XX",
        ];

        const territory88 = [
          "AK",
          "AZ",
          "CA",
          "CO",
          "HI",
          "IL",
          "MN",
          "MT",
          "ND",
          "NM",
          "NV",
          "OR",
          "SD",
          "UT",
          "WA",
          "WI",
          "WY",
        ];

        const territory89 = [
          "AL",
          "AR",
          "FL",
          "GA",
          "IA",
          "KS",
          "LA",
          "MO",
          "MS",
          "NC",
          "NE",
          "OK",
          "SC",
          "TN",
          "TX",
        ];

        if (territory86.includes(value)) {
          this.validDirectSalesTerritories = this.salesTerritories.filter(
            (item) => item.key == 86
          );
          this.whMarsDirectSalesTerritory =
            this.validDirectSalesTerritories &&
            this.validDirectSalesTerritories.length > 0
              ? this.validDirectSalesTerritories[0]
              : null;
        } else if (territory88.includes(value)) {
          this.validDirectSalesTerritories = this.salesTerritories.filter(
            (item) => item.key == 88
          );
          this.whMarsDirectSalesTerritory =
            this.validDirectSalesTerritories &&
            this.validDirectSalesTerritories.length > 0
              ? this.validDirectSalesTerritories[0]
              : null;
        } else if (territory89.includes(value)) {
          this.validDirectSalesTerritories = this.salesTerritories.filter(
            (item) => item.key == 89
          );
          this.whMarsDirectSalesTerritory =
            this.validDirectSalesTerritories &&
            this.validDirectSalesTerritories.length > 0
              ? this.validDirectSalesTerritories[0]
              : null;
        }
      }
    },
  },

  computed: {
    visibleColumns() {
      return this.suggestionHeaders.filter((x) => x.visible);
    },
  },

  watch: {
    addressSelection() {
      this.setNewWarehouseDefaults();
    },
  },

  mounted() {
    this.user = this.$store.getters["user/getUser"];
    this.getIssues();
    this.getWarehouseTemplates();
  },
};
</script>

<style>
tr.v-data-table__selected {
  background: #e65100 !important;
}

.customer-data {
  font-size: 0.9em;
}

.customer-data .row {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-left: 500px;
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
